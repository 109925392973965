<template>
  <div>
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="dashboard-holder">
      <div class="container-fluid">
        <!-- <h5 class="name-welcome">Hi, Charles</h5> -->
        <div class="row">
          <div class="col-md-8 mb-2">
            <div class="balance-card">
              <div class="row">
                <div class="col-md-6">
                  <div class="balance">
                    <p>
                      AVAILABLE BALANCE
                      <span class="pl-2"
                        ><i
                          class="fa fa-eye"
                          :class="[
                            amountVisiblilty == true
                              ? 'fa fa-eye-slash'
                              : 'fa fa-eye'
                          ]"
                          @click="amountVisiblilty = !amountVisiblilty"
                        ></i
                      ></span>
                    </p>
                    <!-- <p>{{ cooporativeData}}</p> -->
                    <h4 v-if="amountVisiblilty">
                      ₦{{
                        Number(cooporativeData.cooperative_bal).toLocaleString()
                      }}
                    </h4>
                    <h4 v-if="!amountVisiblilty">* * * * * * * * *</h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="investments-roi">
                    <div class="row returns-check">
                      <div class="col-md-6">
                        <div
                          class="investment-returns investment-amount"
                          v-if="cooporativeData.investment_sum >= 0"
                        >
                          <small>INVESTMENTS</small>
                          <h6>
                            ₦{{
                              Number(
                                cooporativeData.investment_sum
                              ).toLocaleString()
                            }}
                          </h6>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          class="investment-returns"
                          v-if="cooporativeData.cashback >= 0"
                        >
                          <small>RETURNS</small>
                          <h6>
                            ₦{{
                              Number(cooporativeData.cashback).toLocaleString()
                            }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="fund-withdraw">
                <button
                  class="btn"
                  data-toggle="modal"
                  data-target="#fundwallet"
                >
                  Fund Account
                </button>
                <button
                  class="btn"
                  data-toggle="modal"
                  data-target="#transferFunds"
                >
                  Wihdrawal
                </button>
              </div>
            </div>

            <!-- Investments options -->
            <div class="investments-options-holder">
              <div class="row">
                <div class="col-md-4">
                  <router-link
                    to="/dashboard/invest"
                    style="text-decoration: none;"
                  >
                    <div class="options-holder ivt">
                      <img
                        src="../../assets/icons/investments-icon.svg"
                        alt="investment"
                      />
                      <h6>Investments</h6>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4">
                  <div
                    class="options-holder sl"
                    data-toggle="modal"
                    data-target="#smartlockInfo"
                  >
                    <img
                      src="../../assets/icons/smart-luck-icon.svg"
                      alt="smart-luck"
                    />
                    <h6>Smart lock</h6>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="options-holder coll"
                    data-toggle="modal"
                    data-target="#callaterizedInfo"
                  >
                    <img
                      src="../../assets/icons/colletaral-icon.svg"
                      alt="colletaral"
                    />
                    <h6>Get a loan</h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- Investments options ends -->

            <!-- Recent Activities starts -->
            <div
              class="recent-activities-holder"
              v-if="userInvestmentsTransactions.length > 0"
            >
              <div class="d-flex justify-content-between align-content-center">
                <h6 class="recent-text">Recent Activity</h6>
                <router-link to="investment-transactions">
                  <h5 class="see-more">
                    See More <i class="fa fa-chevron-right"></i>
                  </h5>
                </router-link>
              </div>
              <div>
                <div
                  class="activity-holder"
                  v-for="(transaction,
                  index) in userInvestmentsTransactions.slice(0, 1)"
                  :key="index"
                >
                  <div class="activity-right">
                    <img
                      src="../../assets/icons/notification-icon.svg"
                      alt="notification"
                    />
                    <div>
                      <h6>{{ transaction.type }}</h6>
                      <h5>{{ transaction.created_at | moment2 }}</h5>
                    </div>
                  </div>
                  <div class="left-activity">
                    <h5>₦{{ Number(transaction.amount).toLocaleString() }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- Recent Activities ends -->

            <!-- My Investments starts smart lock -->
            <div class="my-investments-holder" v-if="smartlockData.length > 0">
              <div class="d-flex justify-content-between align-content-center">
                <h5 class="my-investment-text">My Investments</h5>
                <!-- <router-link to="smart-lock">
                    <h5 class="see-more">See More <i class="fa fa-chevron-right"></i></h5>
                </router-link> -->
              </div>
              <div
                class="investments-wrap"
                @click="smartLockDetailsCheck(smart)"
                v-for="(smart, index) in smartlockData"
                :key="index"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="investment-left">
                      <img
                        src="../../assets/images/invest_card_icon.svg"
                        alt="invest"
                      />
                      <div>
                        <h6>Smart lock</h6>
                        <h5>{{ smart.startdate | moment2 }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="investment-right">
                      <h6>
                        ₦{{ Number(smart.amountpersave).toLocaleString() }}
                      </h6>
                      <h5>
                        Earned: ₦{{
                          Number(smart.interestsum).toLocaleString()
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

            <!-- My Investments starts collaterized loan -->
            <div class="my-investments-holder" v-if="loans.length > 0">
              <!-- <h5 class="my-investment-text">My Loans</h5> -->
              <div class="d-flex justify-content-between align-content-center">
                <h5 class="my-investment-text">My Loans</h5>
                <!-- <router-link to="smart-lock">
                    <h5 class="see-more">See More <i class="fa fa-chevron-right"></i></h5>
                </router-link> -->
              </div>
              <div
                class="investments-wrap"
                v-for="(loan, index) in loans"
                :key="index"
                @click="loanDetailsCheck(loan)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="investment-left">
                      <img
                        src="../../assets/images/invest_card_icon.svg"
                        alt="invest"
                      />
                      <div>
                        <h6>{{ loan.collateral }}</h6>
                        <h5>{{ loan.startdate | moment3 }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="investment-right">
                      <h6>₦{{ Number(loan.amount).toLocaleString() }}</h6>
                      <!-- <h5>Earned: ₦{{ Number(smart.interestsum).toLocaleString() }}</h5> -->
                      <h5>Status: {{ loan.status }}</h5>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <!-- My Investments ends -->
          </div>

          <!-- Right side starts -->
          <div class="col-md-4">
            <div class="cooperative-right-side">
              <div class="cooperative-numbers">
                <h5>Total Cooperative members</h5>
                <h2>Updating...</h2>
                <!-- <h2>300</h2> -->
              </div>

              <div class="upcoming-payments">
                <h4 class="upcoming-payment-text">Upcoming payments</h4>
                <div class="payment-holder d-none">
                  <div class="paymemt-top">
                    <p>Rent financing</p>
                    <p>Mar 3, 2024</p>
                  </div>
                  <h3 class="payment-amount">
                    Payment amount: <span>₦5,000</span>
                  </h3>
                  <hr />
                </div>

                <div class="payment-holder d-none">
                  <div class="paymemt-top">
                    <p>Rent financing</p>
                    <p>Mar 3, 2024</p>
                  </div>
                  <h3 class="payment-amount">
                    Payment amount: <span>₦5,000</span>
                  </h3>
                  <hr />
                </div>
              </div>

              <div class="updates-holder d-none">
                <h4 class="update-text">🔔 Updates</h4>
                <h5 class="update-title">Ongoing Projects</h5>
                <div class="updates">
                  <div class="update-top">
                    <p>Greenfield estate phase erwwewewee</p>
                    <p>Mar 3, 2024</p>
                  </div>
                  <h3 class="update-body">
                    Lorem ipsum dolor sit amet consectetur. Odio tellus velit
                    integer fames tortor
                  </h3>
                </div>

                <div class="updates">
                  <div class="update-top">
                    <p>Shelta Rent Payment</p>
                    <p>Mar 3, 2024</p>
                  </div>
                  <h3 class="update-body">
                    Lorem ipsum dolor sit amet consectetur. Odio tellus velit
                    integer fames tortor
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Rigth side ends -->
        </div>

        <!-- Modals for Collaterized loan  starts-->
        <!-- Loan Aomunts starts -->
        <div
          class="modal right-modal fade"
          id="loanamount"
          tabindex="-1"
          aria-labelledby="loanamount"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Loan Amount
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body loan-amount">
                <div class="form-group">
                  <label for="amount" class="input-label"
                    >What amount would you like to request for?</label
                  >
                  <input
                    v-model="collateralAmount2"
                    id="amount"
                    type=""
                    placeholder="Enter loan amount...."
                    class="form-control form-input"
                    onfocus="(this.type='text')"
                  />
                </div>

                <div class="form-group">
                  <label for="Loan duration" class="input-label label-amount"
                    >Loan duration</label
                  >
                  <div class="loan-duration">
                    <button
                      class="btn"
                      :class="[loanDuration === 1 ? 'loanMonthActive' : '']"
                      @click="
                        loanDuration = 1;
                        getServerDateTime();
                      "
                    >
                      1 Month
                    </button>
                    <button
                      class="btn"
                      :class="[loanDuration === 2 ? 'loanMonthActive' : '']"
                      @click="
                        loanDuration = 2;
                        getServerDateTime();
                      "
                    >
                      2 Months
                    </button>
                    <button
                      class="btn"
                      :class="[loanDuration === 3 ? 'loanMonthActive' : '']"
                      @click="
                        loanDuration = 3;
                        getServerDateTime();
                      "
                    >
                      3 Months
                    </button>
                  </div>
                  <div
                    class="form-control form-input month-select"
                    v-if="loanDuration != 0"
                  >
                    <h4 class="">{{ loanDurationFrequency }}</h4>
                  </div>
                </div>

                <div class="form-group">
                  <label for="collat" class="input-label label-amount"
                    >What type of collateral will you provide for the
                    loan?</label
                  >
                  <input
                    v-model="collateral"
                    id="collat"
                    name="collat"
                    type="text"
                    placeholder="Eg. Car"
                    class="form-control form-input"
                  />
                </div>
                <div class="form-group">
                  <label for="collateralImg" class="input-label label-amount"
                    >Collateral Image</label
                  >
                  <input
                    name="collateral"
                    ref="collateral"
                    id="collateralImg"
                    type="file"
                    class="d-none"
                    @change="uploadcollateral('collateral')"
                  />
                  <div
                    class="file-upload-center"
                    @click="$refs.collateral.click()"
                  >
                    <img src="../../assets/icons/file-upload.svg" alt="file" />

                    <p v-if="!uploadingData">Click to upload</p>
                    <p v-if="!uploadingData">
                      PNG, JPG, GIF up to 10MB (max 800 x 400px)
                    </p>
                    <p class="blink_me" v-if="uploadingData">Uploading...</p>
                    <p v-if="uploadingData">File size: {{ fileSizeInMb }}</p>
                    <small v-if="imageUppload" class="uploadedText"
                      >Uploaded: {{ file.name }}</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="evidence" class="input-label label-amount"
                    >Proof of ownership</label
                  >
                  <input
                    name="evidence"
                    ref="evidence"
                    id="evidence"
                    type="file"
                    class="d-none"
                    @change="uploadevidence('evidence')"
                  />
                  <div
                    class="file-upload-center"
                    @click="$refs.evidence.click()"
                  >
                    <img src="../../assets/icons/file-upload.svg" alt="file" />
                    <p v-if="!uploadingData2">Click to upload</p>
                    <p v-if="!uploadingData2">
                      PNG, JPG, GIF up to 10MB (max 800 x 400px)
                    </p>
                    <p class="blink_me" v-if="uploadingData2">Uploading...</p>
                    <p v-if="uploadingData2">File size: {{ fileSizeInMb }}</p>
                    <small v-if="imageUppload2" class="uploadedText"
                      >Uploaded: {{ file.name }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="
                    collateral === '' ||
                      enddate === '' ||
                      startdate === '' ||
                      collateralAmount === '' ||
                      evidenceofownershipimage === '' ||
                      collateralImage === '' ||
                      loanDuration === 0 ||
                      loandurationdays === ''
                  "
                  type="button"
                  class="btn continue"
                  @click="closeLoanAmountModal()"
                  data-toggle="modal"
                  data-target="#previewLoan"
                >
                  Continue
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Loan Amounts starts -->

        <!-- Loan information starts -->
        <div
          class="modal right-modal fade"
          id="callaterizedInfo"
          tabindex="-1"
          aria-labelledby="callaterizedInfo"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Collaterized Loan Informations
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body collateral-info">
                <p>
                  Our collateralized loan is designed to help you meet your
                  financial needs quickly and efficiently. With our loan, you
                  can get the cash you need within 48 hours, without any hassle.
                </p>
                <p>
                  Our loan process is quick, convenient, safe, and secure,
                  ensuring that you get the financial cushion you need to
                  navigate through these tough times. Whether you need money to
                  pay for unexpected expenses or to fund a new project, our loan
                  can provide you with the support you need to achieve your
                  goals.
                </p>

                <h5>Features</h5>
                <hr />
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Minimum amount</h4>
                    <h4>₦500,000</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Interest rate</h4>
                    <h4>Upto 10%</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Maximum duration</h4>
                    <h4>3 Months</h4>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="modal-footer" style="border-top: none;">
                <button
                  type="button"
                  class="btn continue"
                  @click="closeLoanInfoModal()"
                  data-toggle="modal"
                  data-target="#loanamount"
                >
                  Request a loan
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Loan information ends -->

        <!-- Collateral Loan Preview Starts-->
        <div
          class="modal right-modal fade"
          id="previewLoan"
          tabindex="-1"
          aria-labelledby="previewLoan"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Preview Loan Reqest
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body collateral-info">
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-4 info-callat"
                  >
                    <h4>Loan amount</h4>
                    <h4>₦{{ Number(collateralAmount).toLocaleString() }}</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-4 info-callat"
                  >
                    <h4>Interest on loan</h4>
                    <h4>10%</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-4 info-callat"
                  >
                    <h4>Payable Interest</h4>
                    <h4>
                      ₦{{
                        Number(
                          ((collateralAmount * 10) / 100) * loanDuration
                        ).toLocaleString()
                      }}
                    </h4>
                  </div>
                  <hr />
                </div>

                <div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-4 info-callat"
                  >
                    <h4>Payable Amount</h4>
                    <h4>
                      ₦{{
                        Number(
                          ((collateralAmount * 10) / 100) * loanDuration +
                            Number(collateralAmount)
                        ).toLocaleString()
                      }}
                    </h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center info-callat"
                  >
                    <h4>Loan Duration</h4>
                    <h4>{{ loanDurationFrequency }}</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center info-callat"
                  >
                    <h4>Collaretal</h4>
                    <h4>{{ collateral }}</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center info-callat"
                  >
                    <h4>Collateral image</h4>
                    <h4
                      style="cursor: pointer; color: #0033ea"
                      @click="
                        previewUrl = collateralPreview;
                        openImagePreview();
                      "
                    >
                      Preview
                    </h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center info-callat"
                  >
                    <h4>Proof of ownwership</h4>
                    <h4
                      style="cursor: pointer; color: #0033ea"
                      @click="
                        previewUrl = evidencePreview;
                        openImagePreview();
                      "
                    >
                      Preview
                    </h4>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="modal-footer mt-5 d-flex" style="flex-wrap: nowrap;">
                <button
                  type="button"
                  class="btn back"
                  @click="backLoanAmount()"
                >
                  Back
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
                <button
                  type="button"
                  class="btn continue"
                  @click="collateralTermCondition()"
                  data-toggle="modal"
                  data-target="#collaterisedtermsCondition"
                >
                  Continue
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Collateral Loan Preview Starts -->

        <!-- Collateral Loan Terms and Condtions Start -->
        <div
          class="modal right-modal fade"
          id="collaterisedtermsCondition"
          tabindex="-1"
          aria-labelledby="collaterisedtermsCondition"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Terms and Condtions
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body collateral-info">
                <p>
                  These Terms and Conditions ("Terms") govern your use of the
                  loan request service provided on this Platform. By submitting
                  a loan request, you agree to be bound by these Terms. Please
                  read these Terms carefully before proceeding with your loan
                  request:
                </p>
                <p>
                  You must be a Nigerian citizen or legal resident to submit a
                  loan request.
                </p>
                <p>
                  You must be at least 18 years old to apply for a loan.
                </p>
                <p>
                  You must provide accurate and complete information during the
                  loan request process.
                </p>
                <p>
                  Meeting the eligibility criteria does not guarantee approval
                  for a loan.
                </p>
                <p>
                  You may submit a loan request by completing the online
                  application form provided on this platform.
                </p>
                <p>
                  By submitting a loan request, you authorize us to verify the
                  information provided and perform credit checks, and other
                  checks as necessary.
                </p>
                <p>
                  We reserve the right to reject or decline any loan request at
                  our discretion.
                </p>
                <p>
                  Loan approval is subject to verification of your information
                  and meeting our lending criteria.
                </p>
                <p>
                  Approved loans will be disbursed within 24-hours after
                  verification process and according to the terms specified in
                  your loan agreement.
                </p>
                <p>
                  We reserve the right to modify or adjust loan terms, including
                  loan amount and repayment terms, based on our assessment of
                  your creditworthiness and financial situation.
                </p>
                <p>
                  Interest rates and fees associated with your loan will be
                  disclosed in your loan agreement.
                </p>
                <p>
                  You are responsible for paying all applicable interest and
                  fees according to the terms outlined in your loan agreement.
                </p>
                <p>
                  You are required to repay the loan amount according to the
                  repayment schedule specified in your loan agreement.
                </p>
                <p>
                  Failure to make timely repayments may result in late fees,
                  penalties, and negative impacts on your credit score.
                </p>
                <p>
                  We reserve the right to pursue legal action to recover
                  outstanding loan amounts in the event of default.
                </p>
                <p>
                  We are committed to protecting your privacy and safeguarding
                  your personal information in accordance with the Nigerian Data
                  Protection Regulation (NDPR) and other applicable laws.
                </p>
                <p>
                  Your information will be used solely for the purpose of
                  processing your loan request and will not be shared with third
                  parties without your consent.
                </p>
                <p>
                  These Terms shall be governed by and construed in accordance
                  with the laws of Nigeria.
                </p>
                <p>
                  Any disputes arising out of or relating to these Terms shall
                  be resolved amicably by referring same to a Sole Arbitrator,
                  appointed under the FCT Arbitration Law 2009. Where the matter
                  still remains unsettled after arbitration, it shall then be
                  resolved in a court of competent jurisdiction exclusively in
                  Nigeria.
                </p>
                <p>
                  We reserve the right to modify or update these Terms at any
                  time without prior notice.
                </p>
                <p>
                  Your continued use of our loan request service after any
                  changes to these Terms constitutes acceptance of the updated
                  Terms.
                </p>
                <p>
                  By submitting a loan request, you acknowledge that you have
                  read, understood, and agree to be bound by these Terms and any
                  additional terms and conditions referenced herein.
                </p>

                <p>
                  If you have any questions or concerns about these Terms,
                  please contact us at +234 (0) 806 179 6909.
                </p>
              </div>
              <div class="modal-footer mt-5 d-flex">
                <button
                  :disabled="collaterizedLoading"
                  type="button"
                  class="btn continue"
                  @click="applyForCollaterizedLoan()"
                  data-toggle="modal"
                  data-target="#collateralLoanTerms"
                >
                  Submit
                  <i
                    v-if="collaterizedLoading"
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Collateral Loan Terms and Condtions Ends -->

        <!-- Modals for Collaterized loan end -->

        <!-- Modal for smart luck starts -->
        <div
          class="modal right-modal fade"
          id="smartlockInfo"
          tabindex="-1"
          aria-labelledby="smartlockInfo"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Smart Lock Informations
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body collateral-info">
                <p>
                  This product is designed for investors who want to lock up
                  funds for a period of not more than 12 months. The amount
                  saved will be held for the preferred duration and will earn
                  investors upto 20% per annum. At the end of the duration, both
                  the interest and the capital saved will be paid to the
                  investing member.
                </p>

                <h5>Features</h5>
                <hr />
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Minimum amount</h4>
                    <h4>₦5,000</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Interest rate</h4>
                    <h4>Upto 20% per annum</h4>
                  </div>
                  <hr />
                </div>
                <!-- <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Duration</h4>
                    <h4>12 Months</h4>
                  </div>
                  <hr />
                </div> -->
              </div>
              <div class="modal-footer" style="border-top: none;">
                <button
                  type="button"
                  class="btn continue"
                  @click="closeSmartLockInfoModal()"
                  data-toggle="modal"
                  data-target="#smartLockAmount"
                >
                  Start saving
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Smart Lock Amounts starts -->
        <div
          class="modal right-modal fade"
          id="smartLockAmount"
          tabindex="-1"
          aria-labelledby="smartLockAmount"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Save with Smart Lock
                </h5>
                <button
                  @click="
                    modeofsavinglock = 0;
                    smartlockduration = 0;
                    startdate2 = '';
                    amounttolock2 = '';
                  "
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body loan-amount">
                <div class="form-group">
                  <label for="date22" class="input-label"
                    >Lock start date</label
                  >
                  <input
                    v-model="startdate2"
                    id="date22"
                    type="text"
                    placeholder="Select start date"
                    class="form-control form-input"
                    onfocus="(this.type='date'); this.min=new Date().toISOString().split('T')[0]"
                  />
                </div>

                <div class="form-group">
                  <label for="frequency" class="input-label label-amount"
                    >How often would you like to lock?
                  </label>
                  <div class="frequency">
                    <button
                      class="btn"
                      :class="[modeofsavinglock === 1 ? 'frequencyActive' : '']"
                      @click="modeofsavinglock = 1"
                    >
                      Daily
                    </button>
                    <button
                      class="btn"
                      :class="[modeofsavinglock === 2 ? 'frequencyActive' : '']"
                      @click="modeofsavinglock = 2"
                    >
                      Weekly
                    </button>
                    <button
                      class="btn"
                      :class="[modeofsavinglock === 3 ? 'frequencyActive' : '']"
                      @click="modeofsavinglock = 3"
                    >
                      Monthly
                    </button>
                  </div>
                </div>
                <div class="form-group" v-if="modeofsavinglock != 0">
                  <label for="amount" class="input-label"
                    >How much would you like to lock {{ frequency }}?</label
                  >
                  <input
                    v-model="amounttolock2"
                    id="amount"
                    type=""
                    placeholder="Enter lock amount..."
                    class="form-control form-input"
                    onfocus="(this.type='text')"
                  />
                </div>

                <div class="form-group">
                  <label for="Loan duration" class="input-label label-amount"
                    >How long would you like to lock?
                  </label>

                  <div class="loan-duration">
                    <button
                      class="btn"
                      :class="[
                        smartlockduration === 3 ? 'loanMonthActive2' : ''
                      ]"
                      @click="smartlockduration = 3"
                    >
                      3 Months
                    </button>
                    <button
                      class="btn"
                      :class="[
                        smartlockduration === 6 ? 'loanMonthActive2' : ''
                      ]"
                      @click="smartlockduration = 6"
                    >
                      6 Months
                    </button>
                    <button
                      class="btn"
                      :class="[
                        smartlockduration === 9 ? 'loanMonthActive2' : ''
                      ]"
                      @click="smartlockduration = 9"
                    >
                      9 Months
                    </button>

                    <button
                      class="btn"
                      :class="[
                        smartlockduration === 12 ? 'loanMonthActive2' : ''
                      ]"
                      @click="smartlockduration = 12"
                    >
                      12 Months
                    </button>
                  </div>
                  <div
                    class="form-control form-input month-select"
                    v-if="smartlockduration != 0"
                  >
                    <h4 class="" v-if="smartlockduration">
                      {{ smartlockduration }} Months
                    </h4>
                  </div>
                </div>

                <div
                  class="lock-breakdown"
                  v-if="
                    modeofsavinglock != 0 &&
                      amounttolock2 != '' &&
                      smartlockduration != 0
                  "
                >
                  <div class="breakdown-side interest-month">
                    <p>
                      Interest earned over
                      <span>{{ smartlockduration }} months:</span>
                    </p>
                    <p>₦{{ Number(smartLockinterest).toLocaleString() }}</p>
                  </div>
                  <div class="breakdown-side marturity">
                    <p>
                      Amount available at
                      <span>{{ smartlockduration }} </span>months maturity:
                    </p>
                    <p>₦{{ Number(smartLockRoi).toLocaleString() }}</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  :disabled="
                    modeofsavinglock === 0 ||
                      amounttolock2 === '' ||
                      smartlockduration === 0
                  "
                  type="button"
                  class="btn continue"
                  @click="closeSmartLockAmountModal()"
                  data-toggle="modal"
                  data-target="#smartLockPaymentMethod"
                >
                  Select Payment Method
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Smart Lock Amounts End -->

        <!-- Payments methods smart luck starts -->
        <div
          class="modal right-modal fade"
          id="smartLockPaymentMethod"
          tabindex="-1"
          aria-labelledby="smartLockPaymentMethod"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-2"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Select Payment Method
                  <p>
                    Please select your preferred payment method from the options
                    below.
                  </p>
                </h5>
                <button
                  @click="paymentMethod = ''"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body">
                <div class="payments-buttons">
                  <button
                    @click="paymentMethod = 'safe'"
                    :class="[
                      paymentMethod === 'safe' ? 'activePaymentMethod' : ''
                    ]"
                    data-toggle="modal"
                    data-target="#confirmation"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <div>
                      <img
                        src="../../assets/icons/payment-method-safe.svg"
                        alt="safe"
                      />
                      SAFE
                    </div>
                    <div
                      class="check-click"
                      :class="[
                        paymentMethod === 'safe' ? 'activeCheckBox' : ''
                      ]"
                    >
                      <i
                        class="fa fa-check"
                        v-if="paymentMethod === 'safe'"
                      ></i>
                    </div>
                  </button>
                </div>

                <div class="payments-buttons">
                  <button
                    data-toggle="modal"
                    data-target="#confirmation"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="paymentMethod = 'cooperative'"
                    :class="[
                      paymentMethod === 'cooperative'
                        ? 'activePaymentMethod'
                        : ''
                    ]"
                  >
                    <div>
                      <img
                        src="../../assets/icons/payment-method-cooperative.svg"
                        alt="safe"
                      />
                      Cooperative account
                    </div>
                    <div
                      class="check-click"
                      :class="[
                        paymentMethod === 'cooperative' ? 'activeCheckBox' : ''
                      ]"
                    >
                      <i
                        class="fa fa-check"
                        v-if="paymentMethod === 'cooperative'"
                      ></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Smart Lock Amounts starts -->

        <!-- Smart lock unlock funds or end savings starts -->
        <div
          class="modal right-modal fade"
          id="unlockpreview"
          tabindex="-1"
          aria-labelledby="unlockpreview"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-2"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Smart lock details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body">
                <div class="unlock-cover">
                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>Locked amount</h6>
                      <h6>₦{{ smartLockDetails.amountpersave }}</h6>
                    </div>
                    <hr />
                  </div>

                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>Frequency</h6>
                      <h6 v-if="smartLockDetails.modeofsaving == '1'">Daily</h6>
                      <h6 v-if="smartLockDetails.modeofsaving == '2'">
                        Weekly
                      </h6>
                      <h6 v-if="smartLockDetails.modeofsaving == '3'">
                        Monthly
                      </h6>
                    </div>
                    <hr />
                  </div>
                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>Start date</h6>
                      <h6>{{ smartLockDetails.startdate | moment2 }}</h6>
                    </div>
                    <hr />
                  </div>
                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>End date</h6>
                      <h6>{{ smartLockDetails.enddate | moment2 }}</h6>
                    </div>
                    <hr />
                  </div>
                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>Remaining Lock-in Period</h6>
                      <h6>1 Month</h6>
                    </div>
                    <hr />
                  </div>
                  <div class="unlock-holder">
                    <div class="unlock-details">
                      <h6>Accrued Interest</h6>
                      <h6>
                        ₦{{
                          Number(smartLockDetails.interestsum).toLocaleString()
                        }}
                      </h6>
                    </div>
                    <hr />
                  </div>

                  <div class="note">
                    <h5>Note</h5>
                    <h6>
                      You can recall investments within 24hours without any
                      penalty, after 24 hours, every recalled funds will attract
                      a 40% charge on accrued interest.
                    </h6>
                  </div>
                </div>
                <hr />
                <div class="unluck-btn">
                  <button
                    class="btn unluck"
                    data-toggle="modal"
                    data-target="#confirmation-unlock"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Unluck Funds
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal right-modal fade"
          id="loandetailsextend"
          tabindex="-1"
          aria-labelledby="loandetailsextend"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-4"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Get loan details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body collateral-info">
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-4"
                  >
                    <h4>Loan amount</h4>
                    <h4>₦{{ Number(loanDetails.amount).toLocaleString() }}</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Loan Duration</h4>
                    <h4 v-if="loanDetails.duration === '90'">3 Months</h4>
                    <h4 v-if="loanDetails.duration === '60'">2 Months</h4>
                    <h4 v-if="loanDetails.duration === '30'">1 Month</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Remaining balance</h4>
                    <h4>₦500,000</h4>
                  </div>
                  <hr />
                </div>
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Payment status</h4>
                    <h4>{{ loanDetails.status }}</h4>
                  </div>
                  <hr />
                </div>

                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Next payment date</h4>
                    <h4>{{ loanDetails.extension_date | moment2 }}</h4>
                  </div>
                  <hr />
                </div>

                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>Outstanding payments</h4>
                    <h4>₦500,000</h4>
                  </div>
                  <hr />
                </div>
                <div class="note">
                  <h5>Note</h5>
                  <h4>Cooling off Period</h4>
                  <h6
                    style="padding-left: 14px; display: flex; align-items: baseline;"
                  >
                    <p class="dot-style"></p>
                    Cooling off Period You can recall investments within 24hours
                    without any penalty, after 24 hours, every recalled funds
                    will attract a 40% charge on accrued interest.
                  </h6>
                </div>
              </div>
              <div class="modal-footer mt-5 d-flex" style="flex-wrap: nowrap;">
                <button
                  type="button"
                  class="btn continue"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-toggle="modal"
                  data-target="#extendDuration"
                >
                  Extend Loan
                  <!-- <i class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Extend modal duration starts -->
        <div
          class="modal right-modal fade"
          id="extendDuration"
          tabindex="-1"
          aria-labelledby="extendDuration"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-2"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Extention duration
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body">
                <div class="form-group">
                  <label for="duration22" class="input-label"
                    >Extention duration</label
                  >
                  <input
                    type="text"
                    class="form-control form-input"
                    disabled
                    value="1 Month"
                    style="background: transparent;"
                  />
                  <label for="duration22" class="input-label pt-3"
                    >This can be only extended for a month</label
                  >
                </div>
              </div>
              <div class="extend-duration-btn">
                <button
                  class="btn cancel2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  :disabled="extenssionLoading"
                  class="btn continue3"
                  @click="extendCollaterizedLoan()"
                >
                  Submit
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="extenssionLoading"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Extends modal duration ends -->
        <!-- Smart lock unlock funds or end savings ends -->

        <!-- Unlock modal confirmation starts -->
        <div
          class="modal right-modal fade"
          id="confirmation-unlock"
          tabindex="-1"
          aria-labelledby="confirmation-unlock"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-2"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  Confirm Request
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body">
                <p class="confirmation-text-unlock">
                  Ending your Smart Lock before the maturity date may incur
                  penalties. Are you sure you want to proceed?
                </p>
              </div>
              <div class="confirmation-unlock-modal-btn">
                <button
                  class="btn cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  :disabled="unlockLoading"
                  class="btn continue2"
                  @click="unlockSmartLock()"
                >
                  Confirm
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="unlockLoading"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Unlock modal confirmation ends -->

        <!-- Modal for smart luck ends -->

        <!-- Confirmation modal starts -->
        <div
          class="modal right-modal fade"
          id="confirmation"
          tabindex="-1"
          aria-labelledby="confirmation"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-center"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div
                class="modal-header pt-4 pb-2"
                style="margin: 0 14px; padding: inherit;"
              >
                <h5 class="modal-title title-modal" id="rc">
                  CONFIRMATION
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <hr /> -->
              <div class="modal-body">
                <p class="confirmation-text">
                  Are you sure you want to continue?
                </p>
              </div>
              <div class="confirmation-unlock-modal-btn">
                <button
                  class="btn cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  class="btn continue2"
                  @click="startSmartLock"
                  :disabled="smartlockLoading"
                >
                  Continue
                  <i
                    v-if="smartlockLoading"
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  ></i>
                </button>
              </div>
              <!-- <div class="confirmation-modal-btn">
                <button
                  class="btn cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn continue2">Continue</button>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Confirmation modal ends -->

        <!-- Modal to fund wallet starts -->
        <div
          class="modal fade"
          id="fundwallet"
          tabindex="-1"
          aria-labelledby="fundwallet"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div class="modal-header" style="border-bottom: none;">
                <h5 class="modal-title title-modal" id="fundwallet">
                  Fund Wallet
                </h5>
                <button
                  @click="
                    fundType = '';
                    amount2 = '';
                  "
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <input
                      onfocus="(this.type='text')"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      class="form-control"
                      id="amount"
                      name="amount"
                      placeholder="Enter Amount"
                      v-model="amount2"
                      maxlength="11"
                    />
                  </div>
                  <div class="form-group">
                    <label class="d-flex mt-4 choose-label"
                      >Choose how to fund wallet</label
                    >
                    <div class="text-justify">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="fundtype"
                          id="safe"
                          v-model="fundType"
                          value="safe"
                        />
                        <label class="form-check-label radio-label" for="safe"
                          >From SAFE</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="fundType"
                          class="form-check-input"
                          type="radio"
                          name="fundtype"
                          id="paystack"
                          value="paystack"
                        />
                        <label
                          class="form-check-label radio-label"
                          for="paystack"
                          >From Paystack</label
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer" style="border: none;">
                <button
                  type="button"
                  class="btn cancelX"
                  data-dismiss="modal"
                  @click="
                    fundType = '';
                    amount2 = '';
                  "
                >
                  Cancel
                </button>
                <button
                  v-if="fundType === 'safe'"
                  type="button"
                  class="btn continueX"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#review"
                  :disabled="amount === '' || fundType === ''"
                >
                  Continue
                </button>
                <button
                  @click="triggerPaymentModal"
                  v-if="fundType === 'paystack'"
                  type="button"
                  class="btn continueX"
                  data-dismiss="modal"
                  :disabled="amount === '' || fundType === ''"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to fund wallet ends -->

        <!-- Modal to review, fund wallet and confirm starts -->
        <div
          class="modal fade"
          id="review"
          tabindex="-1"
          aria-labelledby="review"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div class="modal-header" style="border-bottom: none;">
                <h5 class="modal-title title-modal" id="review">
                  Review and Confirm
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="review-options">
                    <p>Transfer type</p>
                    <p>From {{ fundType }}</p>
                  </div>
                  <div class="review-options">
                    <p>Amount</p>
                    <p>₦{{ Number(amount).toLocaleString() }}</p>
                  </div>
                </form>
              </div>
              <div class="modal-footer" style="border: none;">
                <button type="button" class="btn cancel" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  v-if="fundType === 'safe'"
                  @click="fundWalletFromSafe"
                  type="button"
                  class="btn continueX"
                  :disabled="loading && fundType === 'safe'"
                >
                  Fund Wallet
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="loading"
                  ></i>
                </button>
                <button
                  v-if="fundType === 'paystack'"
                  @click="triggerPaymentModal"
                  type="button"
                  class="btn continueX"
                >
                  Confirm
                </button>
                <!-- triggerPaymentModal -->
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to review and confirms ends -->

        <!-- Transfer funds modal starts-->
        <div
          class="modal fade"
          id="transferFunds"
          tabindex="-1"
          aria-labelledby="transferFunds"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div class="modal-header" style="border-bottom: none;">
                <h5 class="modal-title title-modal" id="transferFunds2">
                  Transfer Funds
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label class="d-flex mt-2 choose-label"
                      >Balance: ₦{{
                        Number(cooporativeData.cooperative_bal).toLocaleString()
                      }}</label
                    >
                  </div>
                  <div class="form-group">
                    <label class="d-flex mt-4 choose-label"
                      >Choose destination</label
                    >
                    <div class="text-justify">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="safe2"
                          id="stoafe"
                          v-model="transferType"
                          value="tosafe"
                        />
                        <label class="form-check-label radio-label" for="safe"
                          >To SAFE</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="transferType"
                          class="form-check-input"
                          type="radio"
                          name="tobank"
                          id="tobank"
                          value="bank"
                        />
                        <label class="form-check-label radio-label" for="tobank"
                          >Bank Account</label
                        >
                      </div>
                    </div>
                  </div>

                  <div v-if="transferType === 'bank'">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="handler"
                        v-model="bankname"
                      >
                        <option selected disabled value="">--Bank--</option>
                        <option
                          v-for="(bankitem, index) in banks"
                          :key="index"
                          >{{ bankitem }}</option
                        >
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        class="form-control"
                        id="accountf"
                        name="account"
                        placeholder="Beneficiary Account Number"
                        v-model="accountnumber"
                        maxlength="10"
                        type="number"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control"
                        id="accountname"
                        name="accountname"
                        placeholder="Account Name"
                        v-model="accountname"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      onfocus="(this.type='text')"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      class="form-control"
                      id="amountFund"
                      name="amountFund2"
                      placeholder="Enter Amount"
                      v-model="amountFund2"
                      maxlength="11"
                    />
                  </div>
                  <p class="warning-notes">
                    <span>Note:</span>
                    The transfer must be a minimum of 5,000, and it may take up
                    to 24 working hours to process.
                  </p>
                </form>
              </div>
              <div class="modal-footer" style="border: none;">
                <button
                  type="button"
                  class="btn cancelX"
                  aria-label="Close"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  @click="openModalTransaferModal()"
                  type="button"
                  class="btn continueX"
                  :disabled="amountFund2 === '' || transferType === ''"
                >
                  Continue
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="loading"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Transfer funds modal ends -->

        <!-- Modal to review transfer money -->
        <div
          class="modal fade"
          id="rco"
          tabindex="-1"
          aria-labelledby="rco"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div class="modal-header" style="border-bottom: none;">
                <h5 class="modal-title title-modal" id="rc">
                  Review and Confirm
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="review-options">
                    <p>Transfer destination</p>
                    <p v-if="transferType == 'tosafe'">To SAFE</p>
                    <p v-if="transferType == 'bank'">To Bank</p>
                  </div>
                  <div class="review-options">
                    <p>Amount</p>
                    <p>₦{{ Number(amountFund).toLocaleString() }}</p>
                  </div>
                  <div v-if="transferType === 'bank'">
                    <div class="review-options">
                      <p>Bank Name</p>
                      <p>{{ bankname }}</p>
                    </div>
                    <div class="review-options">
                      <p>Account Number</p>
                      <p>{{ accountnumber }}</p>
                    </div>
                    <div class="review-options">
                      <p>Account Name</p>
                      <p>{{ accountname }}</p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer" style="border: none;">
                <button type="button" class="btn cancelX" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn continueX"
                  @click="transferSubmit()"
                >
                  Submit
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="transferLoading"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to review transfer money end -->

        <!-- Modal to preview image upload -->
        <div
          class="modal fade"
          id="previewimage"
          tabindex="-1"
          aria-labelledby="previewimage"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="margin-bottom: 50px;"
          >
            <div class="modal-content">
              <div class="modal-header" style="border-bottom: none;">
                <h5 class="modal-title title-modal" id="previewimage">
                  Preview image
                </h5>
                <button
                 @click="closePreviewImage()"
                  type="button"
                  class="close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <img :src="previewUrl" style="width: 100%;" alt="preview" />
                </form>
              </div>
              <div class="modal-footer" style="border: none;">
                <button
                  type="button"
                  class="btn cancelX"
                  @click="closePreviewImage()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal to preview image starts -->
      </div>
      <PaymentModal
        @paystackReference="dataFromChild($event)"
        @action="dataFromChild($event)"
        :dataForPayment="modalData"
        :responseData="resData"
        v-model="paymentM"
      ></PaymentModal>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
import Vue from "vue";
import $ from "jquery";
import PaymentModal from "../../components/PaymentModal.vue";
import PageLoader from "../../components/PageLoader.vue";
// import Pagination from "../../components/Pagination.vue";
import moment from "moment";
// import PaymentModal from "../../components/PaymentModal.vue";
export default {
  name: "InvestmentDashboard",
  components: {
    PaymentModal,
    // Pagination,
    PageLoader
  },
  // props: ["cooporativeData"],
  data() {
    return {
      loading: false,
      hideDiv: false,
      smartlockLoading: false,
      collaterizedLoading: false,
      extenssionLoading: false,
      unlockLoading: false,
      loanDuration: 0,
      page: 1,
      limit: 10,
      // loanDuration2: "3 Months",
      paymentMethod: "",
      amountVisiblilty: false,
      cooporativeData: "",
      transferLoading: false,
      amount: "",
      amount2: "",
      amountFund: "",
      amountFund2: "",
      account: "",
      amountF: "",
      accountname: "",
      accountnumber: "",
      fundType: "",
      transferType: "",
      bankname: "",
      banks: [],
      modalData: {},
      paymentM: "",
      resData: "",
      reference: "",
      serverDateTime: "",
      smartlockduration: 0,
      // modeofsavinglock: "",
      modeofsavinglock: 0,
      amounttolock: "",
      amounttolock2: "",
      fundlockurl: "",
      frequency: "",
      loanDurationFrequency: "",
      collateralAmount2: "",
      collateralAmount: "",
      startdate: "",
      startdate2: "",
      enddate: "",
      collateral: "",
      loandurationdays: "",
      collateralImage: "",
      evidenceofownershipimage: "",
      file: "",
      fileSizeInMb: "",
      uploadingData: false,
      uploadingData2: false,
      imageUppload: false,
      imageUppload2: false,
      uploadType: "",
      // uploadType2: "evidence",
      smartlockData: [],
      loans: [],
      loanDetails: "",
      smartLockDetails: "",
      userInvestmentsTransactions: [],
      lockdurationdays: "",
      smartLockinterest: 0,
      smartLockRoi: 0,
      previewUrl: "",
      quarterLock: 0,
      collateralPreview: "",
      evidencePreview: ""
    };
  },
  watch: {
    amounttolock2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.amounttolock2 = result));
      this.amounttolock = this.amounttolock2.split(",").join("");
    },
    collateralAmount2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.collateralAmount2 = result));
      this.collateralAmount = this.collateralAmount2.split(",").join("");
    },
    amount2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.amount2 = result));
      this.amount = this.amount2.split(",").join("");
    },
    amountFund2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.amountFund2 = result));
      this.amountFund = this.amountFund2.split(",").join("");
    },
    smartlockduration: function(newValue, oldValue) {
      // const result = newValue
      if (newValue != oldValue) {
        this.smartLockDurationDeterminant();
      }
    },
    amounttolock: function(newValue, oldValue) {
      // const result = newValue
      if (newValue != oldValue) {
        this.smartLockDurationDeterminant();
      }
    },
    amount: function(newValue, oldValue) {
      // const result = newValue
      if (newValue != oldValue) {
        this.smartLockDurationDeterminant();
      }
    },
    modeofsavinglock: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.smartLockDurationDeterminant();
      }
      const result = newValue;
      if (result === 1) {
        return (this.frequency = "daily");
      }
      if (result === 2) {
        return (this.frequency = "weekly");
      }
      if (result === 3) {
        return (this.frequency = "monthly");
      }
    },
    loanDuration: function(newValue) {
      const result = newValue;
      if (result === 1) {
        return (this.loanDurationFrequency = "1 Month");
      }
      if (result === 2) {
        return (this.loanDurationFrequency = "2 Months");
      }
      if (result === 3) {
        return (this.loanDurationFrequency = "3 Months");
      }
    }
  },
  // computed: {
  //   welcome() {
  //     return `${this.cooporativeData}`;
  //   }
  // },
  created() {
    this.$root.$refs.fwi = this;
  },
  mounted() {
    this.investmentData();
    this.getBankNames();
    this.getSmartLocks();
    this.getCollaterizedLoans();
    // this.loanDurationDeterminant();
    // this.getServerDateTime();
  },
  methods: {
    dataFromChild2(data) {
      this.userInvestmentsTransactions = data.data.wallettrans;
    },
    pageUrlGlobal() {
      Api.pushPageURL(`getcooperativetransactions`);
    },
    getServerDateTime() {
      const currentDate = new Date();
      Api.getRequest(`getservertime`).then(res => {
        this.serverDateTime = res.data.date;
        this.loanDurationDeterminant();
      });
    },
    loanDetailsCheck(data) {
      console.log("loanDetails", data);
      this.loanDetails = data;
      $("#loandetailsextend")
        .addClass("fade")
        .modal("show");
    },
    openImagePreview() {
      $("#previewLoan")
        .removeClass("fade")
        .modal("hide");
      $("#previewimage")
        .addClass("fade")
        .modal("show");
    },
    closePreviewImage() {
      $("#previewimage")
        .removeClass("fade")
        .modal("hide");
      $("#previewLoan")
        .addClass("fade")
        .modal("show");
    },
    openModalTransaferModal() {
      // console.log("hello world");
      $("#transferFunds")
        .removeClass("fade")
        .modal("hide");
      $("#rco")
        .addClass("fade")
        .modal("show");
    },
    smartLockDetailsCheck(data) {
      console.log("smartDetails", data);
      this.smartLockDetails = data;
      $("#unlockpreview")
        .addClass("fade")
        .modal("show");
    },
    smartLockDurationDeterminant() {
      const currentDate = new Date();
      // Get the next month
      currentDate.setMonth(currentDate.getMonth() + this.smartlockduration);
      const nextMonthDate = currentDate.toISOString().split("T")[0];
      // console.log("end date", nextMonthDate);
      this.enddate = nextMonthDate;
      const date1 = new Date(this.startdate2);
      const date2 = new Date(this.enddate);
      const time1 = date1.getTime();
      const time2 = date2.getTime();
      const timeDiff = Math.abs(time2 - time1);
      this.lockdurationdays = Math.round(timeDiff / (1000 * 3600 * 24));
      // console.log("Days to lock", this.lockdurationdays);
      // const dailyInterest = "0.000547945205479";
      // const weeklyInterest = "0.003846153846154";
      // const monthlyInterest = "0.016666666666667";

      const weeks = Math.round((date2 - date1) / (7 * 24 * 60 * 60 * 1000));
      // console.log("weeks", weeks);

      let months;
      months = (date2.getFullYear() - date1.getFullYear()) * 12;
      months -= date1.getMonth();
      months += date2.getMonth();
      months <= 0 ? 0 : months;

      const intt = 20;
      // const Q1 = (intt / 100) / 4 = 0.05;
      // const Q2 = Q1 * 2 = 0.1;
      // const Q3 = Q1 * 3 = 0.15;
      // const Q4 = Q1 * 4 = 0.2;

      if (this.modeofsavinglock === 1) {
        this.quarterLock = this.lockdurationdays;
      }
      if (this.modeofsavinglock === 2) {
        this.quarterLock = weeks;
      }
      if (this.modeofsavinglock === 3) {
        this.quarterLock = months;
      }

      if (this.smartlockduration === 3) {
        const smartInterest = Number(
          this.amounttolock * 0.05 * Number(this.quarterLock)
        );
        this.smartLockinterest = Math.round(smartInterest);
      }
      if (this.smartlockduration === 6) {
        const smartInterest = Number(
          this.amounttolock * 0.1 * Number(this.quarterLock)
        );
        this.smartLockinterest = Math.round(smartInterest);
      }
      if (this.smartlockduration === 9) {
        const smartInterest = Number(
          this.amounttolock * 0.15 * Number(this.quarterLock)
        );
        this.smartLockinterest = Math.round(smartInterest);
      }
      if (this.smartlockduration === 12) {
        const smartInterest = Number(
          this.amounttolock * 0.2 * Number(this.quarterLock)
        );
        this.smartLockinterest = Math.round(smartInterest);
      }
      if (this.modeofsavinglock === 2) {
        return (this.smartLockRoi =
          Number(this.amounttolock) * weeks +
          Math.round(this.smartLockinterest));
      }
      if (this.modeofsavinglock === 3) {
        return (this.smartLockRoi =
          Number(this.amounttolock) * months +
          Math.round(this.smartLockinterest));
      }
      if (this.modeofsavinglock === 1) {
        return (this.smartLockRoi =
          Number(this.amounttolock) * this.lockdurationdays +
          Math.round(this.smartLockinterest));
      }
    },
    loanDurationDeterminant() {
      const currentDate = new Date();
      this.startdate = new Date();
      // Get the next month
      currentDate.setMonth(currentDate.getMonth() + this.loanDuration);
      const nextMonthDate = currentDate.toISOString().split("T")[0];
      this.enddate = nextMonthDate;
      const date1 = new Date(this.serverDateTime);
      const date2 = new Date(this.enddate);
      const time1 = date1.getTime();
      const time2 = date2.getTime();
      const timeDiff = Math.abs(time2 - time1);
      this.loandurationdays = Math.round(timeDiff / (1000 * 3600 * 24));
    },
    closeLoanInfoModal() {
      $("#callaterizedInfo")
        .removeClass("fade")
        .modal("hide");

      // $("#bankOptions")
      // .addClass("fade")
      // .modal("show");
    },
    closeLoanAmountModal() {
      $("#loanamount")
        .removeClass("fade")
        .modal("hide");
    },
    backLoanAmount() {
      $("#previewLoan")
        .removeClass("fade")
        .modal("hide");
      $("#loanamount")
        .addClass("fade")
        .modal("show");
    },
    collateralTermCondition() {
      $("#previewLoan")
        .removeClass("fade")
        .modal("hide");
    },
    closeSmartLockInfoModal() {
      $("#smartlockInfo")
        .removeClass("fade")
        .modal("hide");
    },
    closeSmartLockAmountModal() {
      $("#smartLockAmount")
        .removeClass("fade")
        .modal("hide");
    },
    investmentData() {
      this.hideDiv = true;
      Api.getRequest(`getusercooperativedata`)
        .then(res => {
          // console.log("Invest data", res);
          this.hideDiv = false;
          this.pageUrlGlobal();
          this.userInvestmentsTransactions = res.data.wallettrans;
          this.cooporativeData = res.data;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.hideDiv = false;
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
    transferSubmit() {
      if (this.transferType == "tosafe") {
        this.transferTosafe();
      }
      if (this.transferType == "bank") {
        this.transferToBank();
      }
    },
    async transferTosafe() {
      this.transferLoading = true;
      try {
        if (this.amountFund === "") {
          this.transferLoading = false;
          return this.$store.commit("setApiFailed", "Enter Amount");
        }
        if (this.transferType != "tosafe") {
          this.transferLoading = false;
          return this.$store.commit(
            "setApiFailed",
            "Select safe as destination"
          );
        }
        await Api.postRequest(`fundsafefromcooperative`, {
          amount: this.amountFund
        })
          .then(res => {
            // console.log("Transfer to safe done", res);
            this.transferLoading = false;
            $("#rco")
              .removeClass("fade")
              .modal("hide");
            if (res.data.success) {
              this.investmentData();
              // this.$root.$refs.fundInvest.investmentData();
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.transferLoading = false;
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    async transferToBank() {
      this.transferLoading = true;
      try {
        if (this.amountFund === "") {
          this.transferLoading = false;
          return this.$store.commit("setApiFailed", "Enter Amount");
        }
        if (this.transferType != "bank") {
          this.transferLoading = false;
          return this.$store.commit(
            "setApiFailed",
            "Select bank as destination"
          );
        }
        if (this.bankname === "") {
          this.transferLoading = false;
          return this.$store.commit("setApiFailed", "Select bank");
        }
        if (this.accountnumber === "") {
          this.transferLoading = false;
          return this.$store.commit("setApiFailed", "Enter account number");
        }
        if (this.accountname === "") {
          this.transferLoading = false;
          return this.$store.commit("setApiFailed", "Enter account name");
        }
        await Api.postRequest(`transferfromcoperativetobank`, {
          bankname: this.bankname,
          amount: Number(this.amountFund),
          accountnumber: this.accountnumber,
          accountname: this.accountname
        })
          .then(res => {
            // console.log("Transfer to bank done", res);
            $("#rco")
              .removeClass("fade")
              .modal("hide");
            this.transferLoading = false;
            if (res.data.success) {
              this.$root.$refs.fundInvest.investmentData();
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.transferLoading = false;
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    async getBankNames() {
      try {
        await Api.getRequest(`getbanks`)
          .then(res => {
            this.banks = res.data.banks;
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        // console.log("closed connect");
      }
    },
    triggerPaymentModal: function(propInfo) {
      // propInfo.paymodeid = this.paymode;
      // propInfo.addedServicesId = JSON.stringify(this.addedServicesId);
      if (this.amount2 != "") {
        this.fundLoader = true;
        this.modalData.amount = 0;
        this.modalData.payableAmount = Number(this.amount);
        this.modalData.subtitle = "Payment Amount";
        this.modalData.paymentType = "fundwalletinvest";
        this.modalData.headerCaption = "FUND COOPERATIVE";
        this.modalData.headerBackground = "#0033ea";
        // this.modalData.paymentURL = "";
        $("#review")
          .removeClass("fade")
          .modal("hide");
        this.$root.$refs.A.openPaymentOptionsModal();
      } else {
        // $("#fundme").modal("hide");
        return this.$store.commit("setApiFailed", "Enter Amount");
      }
    },
    async startSmartLock() {
      this.smartlockLoading = true;
      if (this.paymentMethod === "safe") {
        this.fundlockurl = "safe";
      }
      if (this.paymentMethod === "cooperative") {
        this.fundlockurl = "cooperative";
      }
      if (this.startdate2 === "") {
        this.smartlockLoading = true;
      }
      if (this.smartlockduration === "") {
        this.smartlockLoading = false;
        return this.$store.commit("setApiFailed", "Lock duration required");
      }
      if (this.modeofsavinglock === "") {
        this.smartlockLoading = false;
        return this.$store.commit("setApiFailed", "Select mode of savings");
      }
      if (this.amounttolock === "") {
        this.smartlockLoading = false;
        return this.$store.commit(
          "setApiFailed",
          "Enter Amount to lock periodically"
        );
      }
      if (this.amounttolock < 5000 || this.amounttolock < "5000") {
        this.smartlockLoading = false;
        return this.$store.commit(
          "setApiFailed",
          "Lock amount can not be less than 5,000"
        );
      }
      if (Number(this.amounttolock) >= 10000000) {
        this.smartlockLoading = false;
        return this.$store.commit(
          "setApiFailed",
          "Lock amount can not be mare than 10,000,000"
        );
      }
      try {
        await Api.postRequest(`startsmartlock`, {
          duration: this.smartlockduration,
          amountpersave: Number(this.amounttolock),
          modeofsaving: this.modeofsavinglock,
          fundtype: this.fundlockurl,
          startdate: moment(this.startdate2).format("YYYY-MM-DD")
        })
          .then(res => {
            this.smartlockLoading = true;
            // console.log("Smart lock started", res);
            if (res.data.success) {
              this.getSmartLocks();
              if (this.paymentMethod === "cooperative") {
                this.investmentData();
              }
              $("#confirmation")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              $("#confirmation")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.smartlockLoading = false;
            console.log(err);
            $("#confirmation")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    async unlockSmartLock() {
      this.unlockLoading = true;
      try {
        await Api.postRequest("terminatesmartlock", {
          smartlock_id: this.smartLockDetails.id
        })
          .then(res => {
            console.log("unlock funds", res);
            this.unlockLoading = false;
            if (res.data.success) {
              this.getSmartLocks();
              $("#confirmation-unlock")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              $("#confirmation-unlock")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.unlockLoading = false;
            console.log(err);
            $("#confirmation-unlock")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        console.log("closed");
      }
    },
    async getSmartLocks() {
      try {
        await Api.getRequest("fetchusersmartlock")
          .then(res => {
            this.smartlockData = res.data.smart_lock;
          })
          .catch(err => {
            console.log("err", err);
            // this.fundLoaderBlue = false;
          });
      } finally {
        // console.log('closed');
      }
    },
    async getCollaterizedLoans() {
      try {
        await Api.getRequest("fetchuserappliedloans")
          .then(res => {
            // console.log("loans", res);
            this.loans = res.data.loans;
          })
          .catch(err => {
            console.log("err", err);
            // this.fundLoaderBlue = false;
          });
      } finally {
        // console.log('closed');
      }
    },
    uploadcollateral: async function(data) {
      this.uploadType = data;
      this.uploadingData = true;
      this.file = this.$refs.collateral.files[0];
      this.collateralImage = this.file.name;
      if (!this.file) {
        this.uploadingData = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif" ||
        this.file.type === "application/pdf" ||
        this.file.type === "svg+xml"
      ) {
        this.uploadingData = true;
      } else {
        this.uploadingData = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select an appropiate image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.uploadingData = false;
        // this.$store.commit("setApiWarning", "");
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be more than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadimportantfile`, formData)
            .then(res => {
              this.$store.commit("setApiWarning", "");
              // console.log("Imgessssss", res);
              this.uploadingData = false;
              if (res.data.error) {
                // this.fileSizeType = true;
                return this.$store.commit("setApiFailed", res.data.error);
              }
              if (res.data.success) {
                this.imageUppload = true;
                this.uploadingData = false;
                this.collateralPreview = res.data.url;
                this.collateralImage = res.data.filename;
                this.$store.commit("setApiSuccess", "success");
              }
            })
            .catch(err => {
              // this.loader2 = false;
              this.uploadingData = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    uploadevidence: async function(data) {
      this.uploadType = data;
      this.uploadingData2 = true;
      this.file = this.$refs.evidence.files[0];
      this.evidenceofownershipimage = this.file.name;
      if (!this.file) {
        this.uploadingData2 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif" ||
        this.file.type === "application/pdf" ||
        this.file.type === "svg+xml"
      ) {
        // this.loader2 = true;
        this.uploadingData2 = true;
      } else {
        // this.loader2 = false;
        this.uploadingData2 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select an appropiate image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.uploadingData2 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be more than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadimportantfile`, formData)
            .then(res => {
              this.$store.commit("setApiWarning", "");
              this.uploadingData2 = false;
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
              if (res.data.success) {
                this.imageUppload2 = true;
                this.uploadingData2 = false;
                this.evidencePreview = res.data.url;
                this.evidenceofownershipimage = res.data.filename;
                this.$store.commit("setApiSuccess", "success");
              }
            })
            .catch(err => {
              // this.loader2 = false;
              this.imageUppload2 = false;
              this.uploadingData2 = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async applyForCollaterizedLoan() {
      this.collaterizedLoading = true;
      if (this.collateralAmount === "") {
        this.collaterizedLoading = false;
        return this.$store.commit("setApiFailed", "Enter loan amount");
      }
      if (this.startdate === "") {
        this.collaterizedLoading = false;
        return this.$store.commit("setApiFailed", "start date not selected");
      }
      if (this.enddate === "") {
        this.collaterizedLoading = false;
        return this.$store.commit("setApiFailed", "End date not selected");
      }
      if (this.collateral === "") {
        this.collaterizedLoading = false;
        return this.$store.commit("setApiFailed", "Enter your collateral");
      }
      if (this.collateralImage === "") {
        this.collaterizedLoading = false;
        return this.$store.commit("setApiFailed", "Select collateral image");
      }
      if (this.evidenceofownershipimage === "") {
        this.collaterizedLoading = false;
        return this.$store.commit(
          "setApiFailed",
          "Select evidence of ownership"
        );
      }
      if (Number(this.collateralAmount) < 500000) {
        this.collaterizedLoading = false;
        return this.$store.commit(
          "setApiFailed",
          "Loan amount is less than 500,000"
        );
      }
      try {
        await Api.postRequest(`applyforcollateralizedloan`, {
          evidence_of_ownership_image: this.evidenceofownershipimage,
          amount: Number(this.collateralAmount),
          collateral: this.collateral,
          startdate: this.startdate,
          enddate: this.enddate,
          duration: Number(this.loandurationdays),
          collateral_image: this.collateralImage
        })
          .then(res => {
            this.collaterizedLoading = true;
            // console.log("Collatarized loan started", res);
            if (res.data.success) {
              this.getCollaterizedLoans();
              $("#collaterisedtermsCondition")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              $("#collaterisedtermsCondition")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.collaterizedLoading = false;
            console.log(err);
            $("#collaterisedtermsCondition")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    async extendCollaterizedLoan() {
      this.extenssionLoading = true;
      try {
        await Api.postRequest(`extendcollaterisedloan`, {
          loan_id: this.loanDetails.id
        })
          .then(res => {
            this.extenssionLoading = true;
            // console.log("Collatarized loan started", res);
            if (res.data.success) {
              this.getCollaterizedLoans();
              $("#extendDuration")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              $("#extendDuration")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.extenssionLoading = false;
            console.log(err);
            $("#extendDuration")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    async fundWalletInvestmentPaystack() {
      // this.fundLoaderBlue = true;
      // console.log("Reffffff:", this.reference);
      if (this.reference === "") {
        return this.$store.commit("setApiFailed", "No payment reference");
      }
      try {
        await Api.postRequest(`fundcooperativefrompaystack`, {
          transaction_reference: this.reference
        })
          .then(res => {
            // console.log("Fund wallet safe response", res);
            // this.fundLoaderWallet = false;
            if (res.data.success) {
              return this.$store.commit(
                "setApiSuccess",
                "Wallet has been funded"
              );
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            // this.fundLoaderBlue = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    // Paystack close function or cancel payment
    close: () => {
      console.log("You closed checkout page");
    },
    async fundWalletFromSafe() {
      this.loading = true;
      if (this.amount === "") {
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter amount");
      }
      if (this.fundType === "") {
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select fund type");
      }
      try {
        await Api.postRequest(`fundcooperativefromsafe`, {
          amount: this.amount
        })
          .then(res => {
            // console.log("fund frm safe", res);
            this.loading = false;
            if (res.data.success) {
              // this.$root.$refs.fundInvest.investmentData();
              $("#review")
                .removeClass("fade")
                .modal("hide");
              this.$router.go();
              return this.$store.commit("setApiSuccess", res.data.message);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    moment3: function(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$gray: #d7d7d7;
$yellow: #ffb100;
$shelta-black: #2b3352;
$shelta-grey: #555c74;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

.dashboard-holder {
  margin-top: 40px;
}

.name-welcome {
  color: $shelta-grey;
  font-family: Lato;
  font-size: 26px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  margin-bottom: 20px;
}

.balance-card {
  text-align: left;
  padding: 16px;
  border-radius: 8px;
  background-color: $secondary;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.balance {
  p {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.5px;
    margin-bottom: 10px;

    span {
      cursor: pointer;
    }
  }

  h4 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
  }
}

.investment-returns {
  small {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
  }

  h6 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
  }
}

.investment-amount {
  border-right: 1px solid #e2e8f0;
}

.fund-withdraw {
  margin-bottom: 20px;

  button:first-child {
    margin-right: 10px;
  }

  button {
    background: #eaecef;
    color: $shelta-black;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
    outline: 0;
    box-shadow: none;
  }
}

// Balance Cards ends

// Types of investments starts
.investments-options-holder {
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 16px;
  border-radius: 8px;
  background-color: $secondary;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.options-holder {
  cursor: pointer;
  padding: 10px;

  img {
    margin-bottom: 10px;
  }

  h6 {
    color: $shelta-grey;
    font-size: 16px;
    font-family: Lato;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 1px;
  }
}

@media only screen and (max-width: 599px) {
  .options-holder {
    margin-bottom: 10px;
  }

  .ivt {
    background: #e4f1ff;
  }

  .sl {
    background: #eafff2;
  }

  .coll {
    background: #ffeaea;
  }
  .name-welcome {
    font-size: 22px;
  }
}

// Types of investemts ends

// Recent Activity starts
.recent-activities-holder {
  background: $secondary;
  border-radius: 4px;
  padding: 14px;
  // display: flex;
  // justify-content: space-between;

  .recent-text {
    color: $shelta-grey;
    font-family: Lato;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.see-more {
  color: #8c91a1;
  font-family: Lato;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}
a {
  text-decoration: none;
}

.fa-chevron-right:before {
  font-size: small;
}
.fa-chevron-right {
  color: #8c91a1;
}

.activity-holder {
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 15px;
}

.activity-right {
  display: flex;
  gap: 8px;
  h6 {
    font-weight: 600 !important;
    text-transform: capitalize;
  }
  h6,
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
    color: $shelta-grey;
    text-align: left;
    margin-bottom: 0px;
  }

  // h6 {
  //   margin-bottom: 2px;
  // }
  // h5 {
  //   margin-bottom : 0px;
  // }
}

.left-activity {
  h5 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 15.5px;
    margin-bottom: 2px;
  }
}

// Recent Activity ends

// My Investments starts
.my-investments-holder {
  padding: 20px 14px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: $secondary;
  border-radius: 4px;
}
.investments-wrap {
  cursor: pointer;
}
.my-investment-text {
  color: $shelta-grey;
  text-align: left;
  font-family: Lato;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
}
.investment-left {
  display: flex;
  gap: 10px;
  div {
    h6 {
      text-align: left;
      color: $shelta-black;
      font-family: Lato;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4px;
      text-transform: capitalize;
    }
    h5 {
      text-align: left;
      color: $shelta-grey;
      font-family: Lato;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 0px;
    }
  }
}
.investment-right {
  h6 {
    text-align: right;
    color: $shelta-black;
    font-family: Lato;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.5px;
  }
  h5 {
    text-align: right;
    color: $shelta-grey;
    font-family: Lato;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 599px) {
  .investment-right {
    padding-left: 40px;
    margin-top: 10px;
    h6,
    h5 {
      text-align: left;
    }
  }
}
// My Investments ends

// Cooperative details and count start
.upcoming-payments {
  // margin-top: 2px;
}

.cooperative-numbers {
  padding: 14px 20px;
  border-bottom: 5px solid #e5e5e5;

  h5 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 19.2px;
    text-align: left;
  }

  h2 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    line-height: 50px;
    text-align: left;
  }
}

.cooperative-right-side {
  //padding: 14px 20px;
  background: $secondary;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

// Cooperative details and count ends

// Upcoming payments starts

.upcoming-payments {
  padding: 14px 20px;
}

.upcoming-payment-text {
  color: $shelta-black;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  text-align: left;
  margin-bottom: 25px;
}

.paymemt-top {
  display: flex;
  gap: 10px;

  p:first-child {
    background: #f8f8f8;
    border-left: 4px solid $primary;
    // height: 29px;
    border-radius: 0px 20px 20px 0px;
    padding: 5px 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* start showing ellipsis when first line is reached */
    white-space: pre-wrap;
  }

  p:last-child {
    padding: 5px 8px;
  }

  p {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
  }
}

.payment-amount {
  margin-top: 10px;
  color: $shelta-grey;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  margin-bottom: 0;

  span {
    font-weight: 700;
  }
}

// Upcoming payments ends

// Updates section starts

.updates-holder {
  padding: 14px 20px;
}

.update-text {
  color: $shelta-black;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
  margin-bottom: 20px;
}

.update-title {
  color: $shelta-black;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 15px;
  margin-bottom: 20px;
}

.updates:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.update-top {
  display: flex;
  gap: 10px;

  p:first-child {
    background: #f8f8f8;
    border-left: 4px solid $primary;
    // height: 29px;
    border-radius: 0px 20px 20px 0px;
    padding: 5px 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* start showing ellipsis when first line is reached */
    white-space: pre-wrap;
  }

  p:last-child {
    padding: 5px 8px;
  }

  p {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
  }
}

.update-body {
  margin-top: 10px;
  color: $shelta-grey;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  margin-bottom: 0;

  span {
    font-weight: 700;
  }
}

// Update section ends

.title-modal {
  color: $shelta-black;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  line-height: 28px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 20px;
  }
}

.close {
  opacity: 1 !important;

  span {
    color: $shelta-black !important;
  }
}

// Collaterized modal starts
.collateral-info {
  p {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
  }

  h5 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;
  }

  h4 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
  }
}
.info-callat {
  gap: 10px;
  h4:first-child {
    text-align: left !important;
  }
  h4:nth-child(2) {
    text-align: right !important;
  }
}

.back {
  border: 1px solid $shelta-grey;
  color: $shelta-grey;
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 48px;
  outline: 0;
}

.continue {
  background: $primary;
  color: $secondary;
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 48px;
  outline: 0;
  box-shadow: none;
}

.input-label {
  color: $shelta-black;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
  display: flex;
}

.form-input {
  border: 1px solid #e7e7e7;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 48px;
  outline: 0;
  box-shadow: none;
}

.month-select {
  height: 48px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: not-allowed;
  margin-top: 12px;

  h4 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
    padding-top: 8px;
  }
}

.label-amount {
  margin-top: 30px;
}

.loan-duration {
  display: flex;
  gap: 10px;

  button {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    color: $shelta-grey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
    height: 48px;
    width: 100%;
  }
}

.file-upload-center {
  border: 1px dashed #ccd6fb;
  border-radius: 6px;
  padding: 40px 14px;
  margin-bottom: 20px;
  cursor: pointer;

  p:nth-child(2) {
    color: $primary;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  p {
    color: $shelta-black;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.loanMonthActive {
  // border: 1px solid $primary !important;
  background: #dde6fc;
}

.loanMonthActive2 {
  // border: 1px solid $primary !important;
  background: #dde6fc;
}

.unlock-cover {
  padding: 40px 14px;
}

.unlock-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h6 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.dot-style {
  background: $shelta-grey;
  border-radius: 50px;
  height: 7px;
  width: 20px;
  margin-bottom: 0;
  margin-right: 8px;
}

.note {
  background: #f6f6f6;
  margin-top: 20px;
  padding: 20px 14px;
  border-radius: 8px;

  h4 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 0;
    text-align: left;
  }

  h5,
  h6 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 0;
    text-align: left;
  }

  h5 {
    font-weight: 600;
  }

  h6 {
    font-weight: 400;
  }
}

.extend-duration-btn {
  padding: 10px 14px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 30px;

  .cancel2 {
    border-radius: 8px;
    height: 44px;
    border: 1px solid #d0d5dd;
    color: $shelta-black;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 48px;
    width: 100%;
    box-shadow: none;
    outline: 0;
  }

  .continue3 {
    border-radius: 8px;
    height: 44px;
    background: $primary;
    color: $secondary;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 48px;
    width: 100%;
    box-shadow: none;
    outline: 0;
  }
}

.confirmation-unlock-modal-btn {
  padding: 10px 14px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 30px;

  .cancel {
    border: 1px solid #d0d5dd;
    // color: #FFB2B2;
    border-radius: 8px;
    height: 44px;
    // background: #D0D5DD;
    color: #2b3352;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    // width: 100%;
    box-shadow: none;
    outline: 0;
  }

  .continue2 {
    border-radius: 8px;
    height: 44px;
    background: $primary;
    color: $secondary;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    // width: 100%;
    box-shadow: none;
    outline: 0;
  }
}

.confirmation-text-unlock {
  color: $shelta-grey;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  // margin-bottom: 0;
  text-align: left;
}

.unluck-btn {
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    background: #ea0033;
    color: $secondary;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    box-shadow: none;
    outline: 0;
    height: 48px;
  }
}

// Smart lock ends
@media only screen and (max-width: 599px) {
  .loan-duration {
    button {
      font-size: 12px;
    }
  }
}

// Method of payments starts
.payments-buttons {
  margin-bottom: 20px;

  button {
    border: 1px solid #e4e7ec;
    border-radius: 12px;
    height: 64px;
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
  }
}

.check-click {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 2px;
  padding-bottom: 2px;

  i {
    font-size: 10px;
    color: $secondary;
  }
}

.activePaymentMethod {
  background: #dde4fc !important;
  border: 1px solid $primary !important;
}

.activeCheckBox {
  background: $primary !important;
  border: 1px solid $primary !important;
}

.interest-month {
  border-bottom: 1px dashed #e5e5ef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.breakdown-side {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10;

  p {
    text-align: left;
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.marturity {
  margin-bottom: 40px;

  p {
    font-weight: 600 !important;
  }
}

.frequency {
  display: flex;
  gap: 10px;

  button {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    color: $shelta-grey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
    height: 48px;
    width: 100%;
  }
}

.frequencyActive {
  background: #dde6fc !important;
  // border: 1px solid $primary !important;
}

// Method of payments ends

// Confirmation Modal  starts
.confirmation-modal-btn {
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;

  .cancel {
    // border: 1px solid #FFB2B2;
    // color: #FFB2B2;
    border: 1px solid#EA0033;
    color: #ea0033;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    // width: 100%;
    box-shadow: none;
    outline: 0;
  }

  .continue2 {
    border: 1px solid $primary;
    color: $primary;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    // width: 100%;
    box-shadow: none;
    outline: 0;
  }
}

.confirmation-text {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  margin-top: 10px;
  text-align: left;
}

// Cond=firmation modal ends

// Fund modal starts
.title-modal {
  color: $shelta-black;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.close {
  opacity: 1 !important;

  span {
    color: $shelta-black !important;
  }
}

.choose-label {
  color: $shelta-black;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-bottom: 12px;
}

.radio-label {
  color: $shelta-grey;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 8px;
}

.modal-footer {
  .cancelX {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: $shelta-black;
    background: #edf2f7;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
    box-shadow: none;
    outline: 0;
  }

  .continueX {
    color: $secondary;
    background: $primary;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
    box-shadow: none;
    outline: 0;
  }
}

.warning-notes {
  font-size: 12px;
  font-family: Lato;
  color: #df2b2b;
  text-align: left;
  margin-bottom: 0;
}

.review-options {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;

  p {
    color: #555c74;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding-bottom: 16px;
    padding-top: 16px;
    margin-bottom: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="select"],
input[type="number"],
input[type="text"] {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 24px !important;
}

.form-group {
  margin-bottom: 24px;

  input[type="select"],
  input[type="text"],
  input[type="number"] {
    margin-bottom: 24px !important;
  }
}

select,
input {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  color: #aaadba;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  outline: 0 !important;
  box-shadow: none !important;
}

// Fund modal ends

// Smart lock ends
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.uploadedText {
  font-family: Lato;
  font-size: 14px;
  line-height: 21px;
  color: #20c997;
}
.right-modal {
  // position:fixed;
  // right: 0;
  // margin: auto;
  // width: 320px;
  // height: 100%;
  // -webkit-transform: translate3d(0%, 0, 0);
  // -ms-transform: translate3d(0%, 0, 0);
  // -o-transform: translate3d(0%, 0, 0);
  // transform: translate3d(0%, 0, 0);
}

// Collaterized modal ends

@media only screen and (max-width: 599px) {
  .marturity {
    p {
      width: 50%;
    }
    p:nth-child(2) {
      text-align: right;
    }
  }
  .breakdown-side {
    p {
      width: 50%;
    }
    p:nth-child(2) {
      text-align: right;
    }
  }
  .cooperative-numbers {
    h2 {
      font-size: 25px;
    }
  }
  .returns-check {
    flex-wrap: nowrap;
  }
  .investment-returns {
    h6 {
      font-size: 18px;
    }
  }
  .balance {
    h4 {
      font-size: 24px;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .options-holder {
    img {
      width: 14%;
    }
    h6 {
      font-size: 14px;
    }
  }
}
</style>
